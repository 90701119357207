import { CheckoutFormFieldsType } from "@/components/Checkout/types"
import {
  BUSINESS_AREA_VALUE_DEFAULT,
  CHECKOUT_SAVED_LOCAL_STORAGE_KEY,
} from "@/hooks/checkout/constants"
import { ShippingMethod } from "@/hooks/shippings/constants"
import { LocalStorageCheckoutType } from "@/utils/common/helpers"
import { getPhoneWithCode } from "@/validations/phone"

export const setCheckoutToStorage = (
  data: Omit<
    LocalStorageCheckoutType,
    "shipping_date" | "comment" | "dataPrivacyAgreement"
  > | null,
): void => {
  if (typeof localStorage === "undefined" || !localStorage) {
    return
  }
  if (data === null) {
    localStorage.removeItem(CHECKOUT_SAVED_LOCAL_STORAGE_KEY)
  } else {
    localStorage.setItem(CHECKOUT_SAVED_LOCAL_STORAGE_KEY, JSON.stringify(data))
  }
}
export const getCheckoutFromStorage = (): LocalStorageCheckoutType | null => {
  if (typeof localStorage === "undefined" || !localStorage) {
    return null
  }
  const data = localStorage.getItem(CHECKOUT_SAVED_LOCAL_STORAGE_KEY)
  return !!data ? (JSON.parse(data) as LocalStorageCheckoutType) : null
}

export const getIsBusinessAreaCustom = (bussiness_area?: string) =>
  bussiness_area === BUSINESS_AREA_VALUE_DEFAULT

export const getShippingAddressByMethod = ({
  formData,
}: {
  formData: Pick<
    CheckoutFormFieldsType,
    | "shipping_address"
    | "shipping_address_courier"
    | "shipping_address_pickup"
    | "shipping_date"
    | "shipping_method"
  >
}): {
  shippingAddress: string
  shippingDate: string
} => {
  const {
    shipping_method,
    shipping_address,
    shipping_address_pickup,
    shipping_address_courier,
    shipping_date,
  } = formData
  const shippingMethod = (shipping_method || "") as ShippingMethod

  if (!shippingMethod) {
    return {
      shippingAddress: "",
      shippingDate: "",
    }
  }

  let shippingAddress = shipping_address || ""
  let shippingDate = !!shipping_date ? shipping_date.toISOString() : ""

  // в зависимости от метода доставки, подставляем нужный адрес
  switch (shippingMethod) {
    case ShippingMethod.PICKUP: {
      shippingAddress = shipping_address_pickup || ""
      shippingDate = ""
      break
    }
    case ShippingMethod.COURIER:
    case ShippingMethod.EXPRESS: {
      shippingAddress = shipping_address_courier || ""
      break
    }
  }

  return {
    shippingAddress,
    shippingDate,
  }
}

export const formatCheckoutData = (data: CheckoutFormFieldsType) => {
  const { shippingAddress, shippingDate } = getShippingAddressByMethod({
    formData: {
      shipping_address: data.shipping_address,
      shipping_method: data.shipping_method,
      shipping_date: data.shipping_date,
      shipping_address_courier: data.shipping_address_courier,
      shipping_address_pickup: data.shipping_address_pickup,
    },
  })

  const isBusinessAreaCustom = getIsBusinessAreaCustom(data.bussiness_area)

  return {
    ...data,
    shipping_address: shippingAddress,
    shipping_date: shippingDate,
    bussiness_area: isBusinessAreaCustom
      ? data.bussiness_area_custom
      : data.bussiness_area,
    not_call: data.not_call,
    phone: getPhoneWithCode(data.phone),
    fio: data.fio.trim(),
  }
}

export const createOrderRequest = (
  token: string,
  data: ReturnType<typeof formatCheckoutData>,
) => ({
  cart: token,
  phone: data.phone,
  fio: data.fio,
  email: data.email,
  bussiness_area: data.bussiness_area,
  shipping_method: data.shipping_method,
  shipping_date: data.shipping_date,
  payer: data.payer,
  shipping_address: data.shipping_address,
  replacement: data.replacement,
  comment: data.comment,
  not_call: data.not_call,
  payment_method: data.payment_method,
})
