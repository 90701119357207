import { SITE_URL } from "@/utils/constants"

type RequestOptionsType = RequestInit

export type DaDataLocationDataType = {
  region_type: string | null
  region: string | null
  city_type: string | null
  city: string | null
  region_kladr_id: string | null
  postal_code: string | null
  region_with_type: string | null
  city_with_type: string | null
  street_with_type: string | null
  house: string | null
  flat: string | null
} | null

export type DaDataLocationType = {
  data: DaDataLocationDataType
} | null

export type DaDataIpLocateRequestType = {
  ip: string
  language?: "en" | "ru"
}

export type DaDataIpLocateResponseType = {
  location: DaDataLocationType
}

export type SuggestAddressesReturnType = {
  suggestions?: {
    unrestricted_value?: string | null
    value?: string | null
    data: DaDataLocationDataType
  }[]
}
export type SuggestFioReturnType = {
  suggestions?: {
    value?: string | null
    unrestricted_value?: string | null
    data: {
      surname: string | null
      name: string | null
      patronymic: string | null
      gender: string | null
      source: string | null
      qc: string | null
    } | null
  }[]
}
export type SuggestEmailReturnType = {
  suggestions?: {
    value: string | null
    unrestricted_value: string | null
    data: {
      local: string | null
      domain: string | null
      type: string | null
      source: string | null
      qc: string | null
    }
  }[]
}

export type SuggestPartyReturnType = {
  suggestions?: {
    data: {
      hid?: string | null
      inn?: null | string
      kpp?: null | string
      ogrn?: null | string
      name?: null | {
        short_with_opf?: string | null
      }
      phones?:
        | null
        | {
            value?: null | string
            data?: {
              type?: null | string | "Мобильный"
              number?: string | null
              source?: null | string
              country_code?: null | string
              city_code?: null | string
            }
          }[]
      management?: {
        name?: string
      } | null
    } | null
    value?: string
    unrestricted_value?: string | null
    defaultName?: string
    defaultInn?: string
  }[]
}

type BoundValueType =
  | "country"
  | "region"
  | "area"
  | "city"
  | "settlement"
  | "street"
  | "house"

type KladrIdType = Record<"kladr_id", string | number>
const KLADR_LIST_DEFAULT: KladrIdType[] = [{ kladr_id: "3400000100000" }]

export interface IfetchAddresse {
  address: string
  locations?: Record<"region_type_full", string | number>[]
  fromBound?: Record<"value", BoundValueType>
  toBound?: Record<"value", BoundValueType>
  kladrId?: string[]
  callSite?: string
}

export const fetchSuggestAddresses = ({
  address,
  locations,
  fromBound,
  toBound,
  kladrId,
}: IfetchAddresse): Promise<SuggestAddressesReturnType> => {
  const locations_boost =
    kladrId !== undefined
      ? [...kladrId.map((id) => ({ kladr_id: id } as KladrIdType))]
      : [...KLADR_LIST_DEFAULT]

  return dadataRequest({
    method: "POST",
    body: {
      query: address,
      locations: locations || [],
      from_bound: fromBound,
      to_bound: toBound,
      locations_boost: locations_boost,
    },
    externalUrl:
      "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
  })
}
export const fetchSuggestFIO = ({
  value,
}: {
  value: string
}): Promise<SuggestFioReturnType> => {
  return dadataRequest({
    method: "POST",
    body: {
      query: value,
    },
    externalUrl:
      "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio",
  })
}

export const fetchSuggestParty = ({
  value,
}: {
  value: string
}): Promise<SuggestPartyReturnType> => {
  return dadataRequest({
    method: "POST",
    body: {
      query: value,
    },
    externalUrl:
      "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party",
  })
}

export const fetchParty = ({
  value,
}: {
  value: string
}): Promise<SuggestPartyReturnType> => {
  return dadataRequest({
    method: "POST",
    body: {
      query: value,
    },
    externalUrl:
      "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party",
  })
}

export const fetchIpLocate = ({
  ip,
  language = "ru",
}: DaDataIpLocateRequestType): Promise<DaDataIpLocateResponseType> => {
  console.log(
    "fetchIpLocate ",
    `${SITE_URL}/api/dadata/iplocate?ip=${ip}&language=${language}`,
  )
  return dadataRequest({
    url: `${SITE_URL}/api/dadata/iplocate?ip=${ip}&language=${language}`,
    method: "GET",
  })
}

export const dadataRequest = <R, Q>({
  url = `${SITE_URL}/api/dadata/suggestions`,
  body,
  method,
  externalUrl = "",
}: {
  url?: string
  body?: Q
  method: "GET" | "POST"
  externalUrl?: string
}): Promise<R> => {
  return dadataFetch({
    url: url,
    options: {
      method: method,
      body:
        method === "POST"
          ? JSON.stringify({ ...(body || {}), externalUrl })
          : undefined,
    },
  })
}

export const dadataFetch = <R>({
  url,
  options,
}: {
  url: string
  options: RequestOptionsType
}): Promise<R> =>
  fetch(`${url}`, options).then((response) => response.json() as Promise<R>)
