import { ISendRecentProducts } from "@/hooks/watchedRecent/types"
import { deleteFetch, get, post, put } from "@/service/fetcher"
import { PayerListItemType } from "@/types"
import {
  AccountRequestUpdatePassword,
  AddressVisibilityType,
  Payer,
  V1AccountUpdateFioUpdatePayload,
} from "../../contracts/contracts"
import { AddressCreate } from "../../contracts/src/api/account"

export type StaffMember = {
  uid: string
  position: string
  fio: string
  email: string
  payers: string[]
  addresses: string[]
}

export type RequestAddStaffMember = {
  position: string
  fio: string
  email: string
  password: string
  payers: string[]
  addresses: string[]
}

export const fetchPayers = (): Promise<PayerListItemType[]> =>
  get("/account/payers", true)

export const fetchPayerCreate = (data: Payer): Promise<{ uid: string }> =>
  post("/account/payer/add", data, true)

export const fetchPayerEdit = ({
  uid,
  ...data
}: Payer & { uid: string }): Promise<{ uid: string }> =>
  put(`/account/payer/${uid}`, data, true)

export const fetchPayerByUid = (uid: string): Promise<Payer> =>
  get(`/account/payer/${uid}`, true)

export const fetchAddresses = (): Promise<
  (AddressVisibilityType & { uid: string })[]
> => get("/account/addresses", true)

export const fetchAddressCreate = (
  data: AddressCreate,
): Promise<{ uid: string }> => post("/account/address/add", data, true)

export const fetchAddressEdit = ({
  uid,
  ...data
}: Omit<AddressCreate, "uid"> & { uid: string }): Promise<{
  uid: string
}> => put(`/account/address/${uid}`, data, true)

export const fetchSetAddressDefault = ({
  uid,
}: {
  uid: string
}): Promise<{ uid: string }> =>
  put(`/account/address/${uid}/default`, null, true)

export const fetchAddressById = ({
  uid,
}: {
  uid: string
}): Promise<AddressCreate> => get(`/account/address/${uid}`, true)

export const fetchUpdatePassword = (
  data: AccountRequestUpdatePassword,
): Promise<null> => put("/account/update-password", data, true)

export const fetchUpdateFio = (
  data: V1AccountUpdateFioUpdatePayload,
): Promise<null> => put("/account/update-fio", data, true)

export const fetchUpdatePhone = (data: {
  phone: string
  code: string
}): Promise<null> => put("/account/update-phone", data, true)

type DocumentResponseType = { link: string } | null

export const fetchAttachedContractPayers = ({
  uid,
}: {
  uid: string
}): Promise<DocumentResponseType> =>
  get(`/account/order/${uid}/attached-documents-contractpayer`, true)

export const fetchGenerateContractPayers = ({
  uid,
}: {
  uid: string
}): Promise<DocumentResponseType> =>
  get(`/account/order/${uid}/generate-document-contractpayer`, true)

export const fetchGenerateInvoicePayment = ({
  uid,
}: {
  uid: string
}): Promise<DocumentResponseType> =>
  get(`/account/order/${uid}/generate-document-invoice-payment`, true)

export const fetchGeneratePackingList = ({
  uid,
}: {
  uid: string
}): Promise<DocumentResponseType> =>
  get(`/account/order/${uid}/generate-document-packing-list`, true)

export const fetchRecommendations = (): Promise<string[] | null> =>
  get("/account/recommendations", true)

export type RecentProductsReturnType = { products: string[] } | null

export const fetchSendRecentProducts = (
  // отправляю id недавно просмотренных товаров
  data: ISendRecentProducts,
): Promise<RecentProductsReturnType> =>
  post("/account/recent-products", data, true)

export const fetchStaff = () =>
  get<StaffMember[] | null>("/account/staff", true)

export const fetchCreateStaff = (data: RequestAddStaffMember) =>
  post<null, RequestAddStaffMember>("/account/staff", data, true)

export const fetchUpdateStaff = (uid: string, data: RequestAddStaffMember) =>
  put<null, RequestAddStaffMember>(`/account/staff/${uid}`, data, true)

export const fetchDeleteStaff = (uid: string) =>
  deleteFetch(`/account/staff/${uid}`, null, true)
